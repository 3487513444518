<template>
  <div>
    <DataTable :value="logs.data" :lazy="true" :paginator="true" :rows="50" ref="dt" :totalRecords="logs.total"
      :loading="loading" @page="onPage($event)" @sort="onSort($event)" responsiveLayout="scroll">
      <Column field="created_at" header="Aanmaakdatum" :sortable="true">
        <template #body="slotProps">
          {{formatDate(slotProps.data.created_at, 'dateTime')}}
        </template>
      </Column>
      <Column field="contact.email" header="Client" :sortable="true">
        <template #body="slotProps">
          {{slotProps.data.contact ? slotProps.data.contact.email : (slotProps.data.user ? 'Medewerker: ' + slotProps.data.user.email : '')}}
        </template>
      </Column>
      <Column field="log_code.action" header="Actie" :sortable="true"></Column>
      
    </DataTable>
</div>
</template>

<script>
  import logservice from "@/services/admin/Logservice"
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';

  export default {
    name: 'contactLogs',
    components: {
      DataTable,
      Column
    },
    data() {
      return {
        logs: {
          'data': [],
          'total': 0
        },
        filters: {'global': {value: null}},
        loading: true,
        lazyParams: {},
        page: 1
      }
    },
    methods: {
      getIndex() {
        this.loading = true
        logservice.index(this.page, {
          lazyEvent: JSON.stringify(this.lazyParams)
        }).then(response => {
            this.loading = false;
            this.logs = response.data
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user error
        })
      },
      onPage(event) {
        this.page = event.page + 1
        this.getIndex();
      },
      onSort(event) {
        this.lazyParams = event;
        this.getIndex();
      },
      onFilter() {
        this.lazyParams.filters = this.filters;
        this.getIndex();
      },
    },
    mounted() {
      this.getIndex()
    }
  }
</script>
