import http from '@/http-common'

const URL = '/api/admin/logs'

class Logservice {
    index(page, params) {
      const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
      return http.get(`${URL}?page=` + page + `&` + queryParams)
    }
}

export default new Logservice()